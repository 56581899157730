
const paths = {
  NEWS: 'blog',
  JOBS: 'job',
  DRIVER: 'product/technology',
  ECOSYSTEM: 'product/technology#approach'
}

/**
 * Link resolver for RichText
 */
export const linkResolver = doc => {
  
  // URL for a category type
  if ( doc.type === 'repeatable_blog_article' ) {
    return `/${paths.NEWS}/${doc.uid}`;

  } else if  (doc.type === 'technology_driver' ) {
    return `/${paths.DRIVER}`;

  } else if  (doc.type === 'technology_ecosystem' ) {
    return `/${paths.ECOSYSTEM}`;
  }

  // Backup for all other types
  return `/${doc.uid}`;
};

/**
 * Link resolver for Prismic GraphQL queries
 */
export const prismicLinkResolver = linkData => {
  if(!linkData) return ""
  
  if( linkData.__typename === 'PRISMIC__ExternalLink' || 
        linkData.__typename === 'PRISMIC__FileLink' ){
    return linkData.url

  } else if( linkData.__typename === 'PRISMIC_Repeatable_blog_article' ){
    return "/"+paths.NEWS+"/"+linkData._meta.uid;

  } else if( linkData.__typename === 'PRISMIC_Technology_driver' ){
    return "/"+paths.DRIVER;

  } else if( linkData.__typename === 'PRISMIC_Technology_ecosystem' ){
    return "/"+paths.ECOSYSTEM;

  } else {
    const tempLink = linkData._meta ? linkData._meta.uid : ''
    return "/"+tempLink;
  }

};