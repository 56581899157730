import React, { useContext, useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitText from '../../utils/SplitText/SplitText';

import { PrismicImage } from 'types/prismicImage';
import { PrismicRichText } from 'types/prismicRichtext';

import { Container } from 'components/container/Container';
import { RichText } from 'components/rich-text/RichText';
import { Image } from 'components/image/Image';
import { Button } from 'components/button/Button';

import { UIContext } from 'context/ui';

import s from './Hero.scss';

gsap.registerPlugin(SplitText);

interface IProps {
  title: string;
  image?: PrismicImage;
  imageMobile?: PrismicImage;
  text?: PrismicRichText;
  imageAlign?: 'left' | 'center' | 'right';
  clearPaddingBottom?: boolean;
  cta?: {
    label: string;
    href: string;
  };
}

export const Hero = ({
  title,
  image,
  imageMobile,
  text,
  imageAlign='right',
  clearPaddingBottom=false,
  cta
}: IProps) => {

  const { isMobile, isDesktop } = useContext(UIContext);
  const { imagesPreloaded } = useContext(UIContext);

  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLHeadingElement>(null);
  const ctaRef = useRef<HTMLHeadingElement>(null);
  const imgWrapRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  let tl:GSAPTimeline;

  useEffect( ():any => {

    if (imagesPreloaded && titleRef.current && imgWrapRef.current && imgRef.current) {
      tl = gsap.timeline({ paused: true });
      const titleLines = new SplitText(titleRef.current, { type: 'lines'}).lines;

      titleLines.forEach((line) => {
        const div = document.createElement('div');
        div.classList.add('line');
        line.parentNode.insertBefore(div, line);
        div.appendChild(line);
      });

      const yPercent = 100;
      const scale = isDesktop ? 1.2 : 1;

      // Transition 1
      const animStartDelay = window.reactTransitionGroupTriggered ? .85 : 0;

      tl
        .addLabel('start')
        .set(titleLines, { yPercent: 100 })
        .set(titleRef.current, { autoAlpha: 1 })
        .set([imgWrapRef.current, imgRef.current], { autoAlpha: 1 })
        .set(imgWrapRef.current, { yPercent })
        .set(imgRef.current, { scale })
        .set(imgRef.current, { yPercent: -yPercent })

        .to(imgWrapRef.current, { duration: 1, ease: 'connect-easing1', yPercent: 0 }, `start+=${animStartDelay+.75}`)
        .to(imgRef.current, { duration: 1, ease: 'connect-easing1', yPercent: 0}, `start+=${animStartDelay+.75}`)
        .to(imgRef.current, { duration: 1, ease: 'connect-easing1', scale: 1 }, `start+=${animStartDelay+.75}`)
        .to(titleLines, { duration: 1, stagger: .15, ease: "connect-easing1", yPercent: 0 }, `start+=${animStartDelay+1}`)

          if (textRef.current) {
            tl
              .set(textRef.current, { alpha: 0 }, 'start')
              .to(textRef.current, { duration: 2, ease: 'connect-easing1', alpha: 1 }, `start+=${animStartDelay+1.1}`);
          }

          if (ctaRef.current) {
            tl
              .set(ctaRef.current, { alpha: 0 }, 'start')
              .to(ctaRef.current, { duration: 2, ease: 'connect-easing1', alpha: 1 }, `start+=${animStartDelay+1.2}`);
          }

      tl.play();
/*

      // Transition 2; STRAIGHT
      const animStartDelay = 0;

      tl
        .addLabel('start')
        .set(titleLines, { yPercent: 100 })
        .set(titleRef.current, { autoAlpha: 1 })
        .set(textRef.current, { alpha: 0 })
        .set(ctaRef.current, { alpha: 0 })
        .set([imgWrapRef.current, imgRef.current], { autoAlpha: 1 })
        .set(imgWrapRef.current, { yPercent })
        .set(imgRef.current, { scale })
        .set(imgRef.current, { yPercent: -yPercent })

        .to(imgWrapRef.current, { duration: 0, ease: 'connect-easing2', yPercent: 0 }, `start+=${animStartDelay+.5}`)
        .to(imgRef.current, { duration: 0, ease: 'connect-easing2', yPercent: 0}, `start+=${animStartDelay+.5}`)
        .to(imgRef.current, { duration: 1, ease: 'connect-easing2', scale: 1 }, `start+=${animStartDelay+1}`)
        .to(titleLines, { duration: 1, stagger: .15, ease: "connect-easing2", yPercent: 0 }, `start+=${animStartDelay+1.35}`)
        .to(textRef.current, { duration: 2, ease: 'connect-easing2', alpha: 1 }, `start+=${animStartDelay+.1}`)
        .to(ctaRef.current, { duration: 2, ease: 'connect-easing2', alpha: 1 }, `start+=${animStartDelay+.1}`)
      tl.play();
      */
    }

    return () => tl && tl.kill()

  }, [imagesPreloaded]);

  return (
    <div className={s('hero', imageAlign, {clearPaddingBottom} )}>
      <Container>
        <div className={s.hero__row__top}>
          <div className={s.hero__row__top__col}>
            <h1 className={`u-visually-hidden`}>{title}</h1>
            <h1 ref={titleRef} className={s.hero__title} style={{opacity: 0}} aria-hidden="true">
              {title}
            </h1>
            {text &&
              <div ref={textRef} className={s.hero__text}>
                <RichText>{text}</RichText>
              </div>
            }
            {cta &&
              <div className={s.hero__ctaContainer} ref={ctaRef} >
                <Button label={cta.label} href={cta.href} ></Button>
              </div>}
          </div>
        </div>
        <div className={s.hero__row__bottom}>
          <div className={s.hero__row__bottom__col}>
            <div ref={imgWrapRef} className={s.hero__image}>
              { (isDesktop || !imageMobile) && <div ref={imgRef} > { (image) && <Image image={image} preload={true} loading='eager' />} </div>}
              { isMobile && imageMobile && <div ref={imgRef} ><Image image={imageMobile} preload={true} loading='eager' /></div>}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
