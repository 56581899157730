import { RichText as PrismicRichText } from 'prismic-reactjs';
import React from 'react';

import { linkResolver } from 'utils/linkResolver';
import { Link } from 'components/link/Link';

import { IPrismicRichText } from 'types/prismicRichtext';

import s from './RichText.scss';

interface IProps {
  children: any;
  pageThemeLight?: boolean;
  fontSizeBig?: boolean;
}

const link = (
  type: any,
  element: any,
  content: any,
  children: any,
  index: any
) => {
  const target = element.data && element.data.target;
  
  const key = element.data.id || index;

  if (element.data && ( element.data.link_type === "Web" || element.data.link_type === "Media" )) {
    return (
      <a key={key} href={element.data.url} target='_blank'>
        {children}
      </a>
    );
  }

  return (
    <Link key={key} to={linkResolver(element.data)} target={target}>
      {children}
    </Link>
  );
};

export function asText(value?: IPrismicRichText | null | undefined): string {
  // just in case someone sends us a string
  if (typeof value === 'string') {
    return value;
  }

  return PrismicRichText.asText(value || []);
}

const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const RichText = ({ children, pageThemeLight=false, fontSizeBig=false }: IProps) => {
  if (isEmpty(children)) {
    return null;
  }

  return (
    <div className={s('richText', {pageThemeLight}, {fontSizeBig} ) }>
      <PrismicRichText render={children} serializeHyperlink={link} />
    </div>
  );
};
