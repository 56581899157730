import React from 'react';

import { Seo } from 'components/contentful/seo/Seo';

import { Hero } from 'components/hero/Hero';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';
import { WrapperBgs } from 'components/container/WrapperBgs'
import { BgLine1 } from 'components/bg-svgs/lines/home/bg-line-1/BgLine1';

import s from 'styles/pages/404.scss';

// tslint:disable no-default-export
export default () => (
  <>
    <Seo title="Page Not Found" />

    <BgGradient />

    <div className={s.page404} >

      <WrapperBgs>
        <div style={{position: 'absolute', zIndex: 1, width: '100%'}}>
          <BgLine1 />
        </div>
        <Hero
          title="Page not Found"
          text={[{
            spans: [],
            text: "The page you are looking for isn't accessible or doesn't exist.",
            type: "paragraph"
          }]}
          cta={{ label: 'Go to Homepage', href: '/' }}
          imageAlign="center"
        />
        <div style={{height: '30px'}}></div>
      </WrapperBgs>

    </div>

  </>
);
