import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { scroll } from 'constants/Scroll'
import { PrismicImage } from 'types/prismicImage';
import s from './Image.scss';

interface IProps {
  image: PrismicImage;
  aspectRatio?: string;
  className?: string;
  loading?: 'eager' | 'lazy';
  preload?: boolean;
  srcSetMobile?: boolean;
}

export const Image = (
  {
    image,
    aspectRatio,
    className,
    loading = 'lazy',
    preload = false,
    srcSetMobile = false
  }: IProps) => {

  //const srcSet = !src2x ? undefined : `${src} 1x, ${src2x} 2x, ${src2x} 1200w`;
  const width = image?.dimensions.width;
  const height = image?.dimensions.height;
  const aspectRatioValue = aspectRatio ? aspectRatio : `${(height/width)*100}%`

  const figureRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect( () => {
    setTimeout(() => {
      if( loading === 'lazy' ){
        ScrollTrigger.create({
          scroller: scroll.container,
          trigger: figureRef.current,
          start: "-200% 100%",
          onEnter: () => {
            if(imgRef.current){
              imgRef.current.src = imgRef.current?.dataset.src
              if(imgRef.current?.dataset.srcset){
                imgRef.current.srcset = imgRef.current?.dataset.srcset
              }
            }
          }
        });
      }
    }, 1500)
  }, [] )

  const imgLoaded = (e:any) => {
    if(e.currentTarget.src.indexOf('data:') === -1){
      figureRef.current?.setAttribute('data-loaded', 'true')
    }
  }

  return (
    <figure className={s('figure', loading )} style={{paddingTop: aspectRatioValue }} ref={figureRef} >
      <img
        src={ loading==='eager' ? image?.url : require('assets/images/aurora.png').default}
        data-src={ loading==='lazy' ? image?.url : undefined}
        data-srcset={ srcSetMobile ? `${image?.url}&w=750 750w, ${image?.url} 1x` : undefined}
        sizes={ srcSetMobile ? `(max-width: 750px) 750px` : ''}
        alt={image?.alt}
        width={width}
        height={height}
        className={className}
        data-preload={preload}
        ref={imgRef}
        onLoad={ loading==='lazy' ? imgLoaded : undefined }
      />
    </figure>
  );
};
